<template>
  <div
    class="w-full xxs:py-10 md:py-0 h-auto md:h-screen flex gap-4 flex-col justify-center -bg-red-500 bg-[#F8F8F8] items-center font-bold"
  >
    <div
      class="flex xxs:flex-col gap-12 md:flex-row w-[90%] h-[80%] justify-between -border"
    >
      <div class="flex flex-col md:min-w-[360px] md:w-[25%] h-full -bg-slate-500">
        <CheckOut />
      </div>
      <div
        class="flex flex-col md:w-[70%] rounded-xl justify-center items-center h-[700px] md:h-full bg-[#ffffff]"
      >
        <ApiResponse />
      </div>
    </div>
    <div class="flex flex-col w-[90%] h-[200px] max-width-[300px] md:h-[10%] -bg-slate-400">
      <FormFooter />
    </div>
  </div>
</template>

<script>
import CheckOut from "./components/CheckOut.vue";
import ApiResponse from "./components/ApiResponse.vue";
import FormFooter from "./components/FormFooter.vue";

export default {
  name: "App",

  components: {
    CheckOut,
    ApiResponse,
    FormFooter,
  },
  methods: {
   


}, 
};
</script>

<style></style>
