<template>
  <div
    class="w-full h-screen flex flex-col gap-2 justify-center items-center font-bold text-red-400"
  >
    <div
      class="flex w-full h-[30%] justify-start !items-center -bg-yellow-200 gap-3"
    >
      <span class="text-[16px] font-[500] leading-[14.52px] text-[#333333]">© Dimensional Sys</span>
      <div class="w-[10px] h-[10px] rounded-full bg-[#333333]"></div>
      <span class="text-[14px] leading-[10.89px] font-[400] text-[#333333] cur">Contact Us</span>
      <span class="text-[14px] leading-[10.89px] font-[400] text-[#333333]">About Us</span>
    </div>
    <div>
      <p class="text-[#828282] font-[300] text-[16px] xxs:leading-[16.52px] md:leading-[14.52px] text-justify">
        <span class="font-bold text-[18px] leading-[18.52px] text-[#828282]">Disclaimer:</span> This demo is for informational purposes only. The actions performed here are examples of Stripe’s payment gateway functionalities and do not result in real transactions or charges. Ensure you have the correct access credentials and permissions before implementing these features in a live environment. Test data may be used, and actual functionality could vary based on account setup and region.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormFooter",
};
</script>

<style></style>
